import {createRouter, createWebHistory} from "vue-router";
import Main from "@/pages/Main.vue";
import About from "@/pages/About.vue";
import Skills from "@/pages/Skills.vue";
import Works from "@/pages/Works.vue";
import RestApi from "@/pages/RestApi.vue";
import Contacts from "@/pages/Contacts.vue";
import Sharpxch from "@/pages/Sharpxch.vue";
import Blog from "@/pages/Blog.vue";
import Environment from "@/pages/Environment.vue";

const routes = [
    {
        path: '/',
        component: Main
    },
    {
        path: '/about',
        name: 'about',
        component: About
    },
    {
        path: '/skills',
        name: 'skills',
        component: Skills
    },
    {
        path: '/works',
        name: 'works',
        component: Works
    },
    {
        path: '/rest-api',
        name: 'rest-api',
        component: RestApi
    },
    {
        path: '/contacts',
        name: 'contacts',
        component: Contacts
    },
    {
        path: '/sharpxch',
        name: 'sharpxch',
        component: Sharpxch
    },
    {
        path: '/blog',
        name: 'blog',
        component: Blog
    },
    {
        path: '/environment',
        name: 'environment',
        component: Environment
    },
    {
        path: '/:notFound(.*)',
        redirect: '/'
    }
];
const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router;
